
    
    <template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-if="!loading" my-2>
            <form @submit.prevent="update_comments" autocomplete="off">
                <v-layout row wrap>
                    
        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="comments.comment_text" :label="$store.getters.lang.data.comment_text"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        


        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="number" v-model="comments.comment_date" :label="$store.getters.lang.data.comment_date"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        


        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="comments.comment_image" :label="$store.getters.lang.data.comment_image"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        



            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="users" v-model="comments.user_id" outlined  item-text="user_id"
                            item-value="user_id" :return-object="false" :label="$store.getters.lang.data.user_id">
                        </v-select>
                        </v-flex>
            

            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="items" v-model="comments.item_id" outlined  item-text="item_id"
                            item-value="item_id" :return-object="false" :label="$store.getters.lang.data.item_id">
                        </v-select>
                        </v-flex>
            
        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="number" v-model="comments.comment_like_count" :label="$store.getters.lang.data.comment_like_count"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        


        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="number" v-model="comments.comment_report_count" :label="$store.getters.lang.data.comment_report_count"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        


        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="comments.comment_status" :label="$store.getters.lang.data.comment_status"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        

    
    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" rounded class="mx-1"  type="submit" x-large>{{$store.getters.lang.data.update_btn}}</v-btn>
                    </v-flex>    
                </v-layout>
            </form>
        </v-container>
        
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>

<script>
    import controller from './../../controller/comments'
    export default {
        data() {
            return {
                comments: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {
            
                    users (){
                        return this.$store.getters.users
                    },
                    
                    items (){
                        return this.$store.getters.items
                    },
                    
            
        },
        methods: {
            get_comments() {
                this.loading = true
                controller.getOne(this.id).then(r =>{
                    this.comments = r.data.row
                    this.loading = false
                }).catch(e =>{
                    console.log(e);
                })
            },
            update_comments() {
                controller.update_comments(this.comments).then(r => {
                    if (r.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Updated',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Not Updated',
                            color: 'error'
                        }
                    }
                })
            },
            
        },
        mounted() {
            this.id = this.$route.params.id
            this.get_comments()
        },
    }
</script>
    
    